import cookieNoCrumbs from 'assets/img/cookie-no-crumbs.svg';
import React from 'react';
import styled, { keyframes } from 'styled-components';

// Adjust the keyframes so opacity starts at 1 when animation begins.
const crumbFall = keyframes`
  0% {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
  80% {
    opacity: 0.8;
  }
  100% {
    transform: translateY(130px) scale(0.8);
    opacity: 0;
  }
`;

const crumbShapes = [
  'polygon(10% 0%, 100% 10%, 90% 60%, 30% 100%, 0% 80%)',
  'polygon(0% 20%, 50% 0%, 100% 20%, 90% 60%, 40% 90%)',
  'polygon(20% 0%, 80% 0%, 100% 50%, 70% 80%, 0% 70%)',
  'polygon(0% 10%, 60% 0%, 100% 30%, 90% 100%, 10% 100%)',
  'polygon(10% 0%, 90% 0%, 100% 30%, 50% 100%, 0% 70%)'
];

const crumbColors = ['#B06C42', '#D5923F'];

function getRandomInt(max: number): number {
  return Math.floor(Math.random() * max);
}

interface WrapperProps {
  size: number; // size in pixels
}

const Wrapper = styled.div<WrapperProps>`
  position: relative;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  overflow: hidden;
`;

const CookieImage = styled.img`
  width: 100%;
  height: 100%;
  display: block;
  position: relative;
  z-index: 2;
`;

interface CrumbProps {
  shapeIndex: number;
  parentSize: number;
}

const Crumb = styled.span<CrumbProps>`
  position: absolute;
  top: 25%;
  width: ${props => `${(props.parentSize * (4 + getRandomInt(3))) / 200}px`};
  height: ${props => `${(props.parentSize * (4 + getRandomInt(3))) / 200}px`};
  opacity: 0;
  animation: ${crumbFall} 2s linear infinite;
  z-index: 1;
`;

interface AnimatedCookieProps {
  size?: number;
}

const AnimatedCookie: React.FC<AnimatedCookieProps> = ({ size = 200 }) => {
  const crumbs = Array.from({ length: 40 }).map((_, index) => {
    const shapeIndex = getRandomInt(crumbShapes.length);
    const leftOffset = 60 + Math.random() * 40;
    const delay = `${(index * 0.3).toFixed(1)}s`;
    const color = crumbColors[getRandomInt(crumbColors.length)];

    return (
      <Crumb
        key={index}
        shapeIndex={shapeIndex}
        parentSize={size}
        style={{
          left: `${leftOffset}%`,
          animationDelay: delay,
          clipPath: crumbShapes[shapeIndex],
          backgroundColor: color
        }}
      />
    );
  });

  return (
    <Wrapper size={size}>
      <CookieImage src={cookieNoCrumbs} alt="Cookie" />
      {crumbs}
    </Wrapper>
  );
};

export default AnimatedCookie;
