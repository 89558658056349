import { useTranslation } from 'react-i18next';

import {
  SEARCH_INPUT_CLOSE_ICON_TEST_ID,
  SEARCH_INPUT_TEST_ID
} from 'constants/tests';
import { ReactComponent as CloseIcon } from 'assets/img/close.svg';

import {
  inputCloseIconStyles,
  inputStyles,
  inputWrapperStyles
} from './SearchInput.styles';

type Props = {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus: (event: React.ChangeEvent<HTMLInputElement>) => void;
  searchValue: string;
  onCloseIconClick: () => void;
};

const SearchInput = ({
  onChange,
  onFocus,
  searchValue,
  onCloseIconClick
}: Props) => {
  const { t } = useTranslation();

  return (
    <div className={inputWrapperStyles}>
      <input
        data-testid={SEARCH_INPUT_TEST_ID}
        onChange={onChange}
        type="text"
        className={inputStyles}
        onFocus={onFocus}
        placeholder={t('searchProduct')}
        value={searchValue}
      />

      {searchValue.length > 0 && (
        <CloseIcon
          data-testid={SEARCH_INPUT_CLOSE_ICON_TEST_ID}
          onClick={onCloseIconClick}
          className={inputCloseIconStyles}
        />
      )}
    </div>
  );
};

export default SearchInput;
