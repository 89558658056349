import { Link } from 'react-router-dom';

import { logoStyles } from './Logo.styles';

import CookieLogo from 'assets/img/cookie.svg';

const Logo = () => {
  return (
    <Link to="/">
      <img alt="logo" src={CookieLogo} className={logoStyles} />
    </Link>
  );
};

export default Logo;
