import styled from '@emotion/styled';
import { black, white } from 'assets/styles/colors';
import { borderRadius, space2, space4 } from 'assets/styles/variables';

type Props = {
  filled?: boolean;
  fullWidth?: boolean;
};

export const StyledButton = styled.button`
  padding: ${space2} ${space4};
  border-radius: ${borderRadius};
  border: 1px solid ${black};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'inherit')};
  cursor: pointer;
  background-color: ${({ filled }: Props) => (filled ? black : 'transparent')};
  color: ${({ filled }: Props) => (filled ? white : 'inherit')};

  :disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;
