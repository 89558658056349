import styled from '@emotion/styled';
import { QUERIES } from 'assets/styles/breakpoints';
import { gray10 } from 'assets/styles/colors';
import { font_md, space1, space3, space4 } from 'assets/styles/variables';
import { space2 } from 'assets/styles/variables';
import { borderRadius } from 'assets/styles/variables';

const Wrapper = styled.div`
  display: flex;
  gap: 1px;
  background: ${gray10};
  padding: ${space1};
  border-radius: ${borderRadius};
  width: fit-content;
`;

const SegmentButton = styled.button<{ isActive: boolean }>`
  padding: ${space2} ${space4};
  border: none;
  border-radius: ${borderRadius};
  cursor: pointer;
  background: ${({ isActive }) => (isActive ? '#fff' : 'transparent')};
  transition: background 0.2s;
  font-weight: ${({ isActive }) => (isActive ? '600' : '400')};

  ${QUERIES.md} {
    padding: ${space1} ${space3};
    font-size: ${font_md};
  }
`;

type Option<T extends string> = {
  value: T;
  label: string;
};

type SegmentedControlProps<T extends string> = {
  options: Array<Option<T>>;
  activeOption: T;
  onChange: (value: T) => void;
};

const SegmentedControl = <T extends string>({
  options,
  activeOption,
  onChange
}: SegmentedControlProps<T>) => {
  return (
    <Wrapper>
      {options.map(option => (
        <SegmentButton
          key={option.value}
          isActive={activeOption === option.value}
          onClick={() => onChange(option.value)}
        >
          {option.label}
        </SegmentButton>
      ))}
    </Wrapper>
  );
};

export default SegmentedControl;
