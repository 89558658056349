import { css } from '@emotion/css';
import { white } from 'assets/styles/colors';
import { tabletMedium, tabletSmall } from 'assets/styles/media-queries';
import {
  boxShadow10,
  font_lg,
  font_md,
  font_sm,
  font_xl,
  font_xxl,
  space2,
  space3,
  space5,
  space7
} from 'assets/styles/variables';

export const singleProductWrapperStyles = css`
  display: flex;
  flex-direction: row;
  width: 100%;
  background-color: ${white};
  padding: ${space7};
  box-shadow: ${boxShadow10};

  ${tabletMedium} {
    flex-direction: column;
  }

  ${tabletSmall} {
    padding: ${space5};
  }
`;

export const singleProductImageWrapperStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 210px;
  margin-right: ${space7};

  ${tabletMedium} {
    margin-right: 0;
    margin-bottom: ${space7};
    flex-direction: column;
  }

  ${tabletSmall} {
    margin-bottom: ${space5};
  }
`;

export const singleProductImageStyles = css`
  max-height: 200px;
  max-width: 200px;

  ${tabletSmall} {
    max-height: 120px;
    max-width: 120px;
  }
`;

export const singleProductInfoWrapperStyles = css`
  display: flex;
  flex-direction: column;

  ${tabletMedium} {
    align-items: center;
  }
`;

export const singleProductNameStyles = css`
  font-size: ${font_xxl};
  font-weight: 600;
  margin-bottom: ${space3};

  ${tabletSmall} {
    font-size: ${font_lg};
    margin-bottom: ${space2};
  }
`;

export const singleProductDescriptionStyles = css`
  font-size: ${font_xl};
  margin: ${space3} 0;

  ${tabletSmall} {
    font-size: ${font_md};
    margin: ${space2} 0;
  }
`;
