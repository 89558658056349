import { css } from '@emotion/css';
import { primary } from 'assets/styles/colors';
import { tabletSmall } from 'assets/styles/media-queries';
import { font_xl, space2, space3, space4 } from 'assets/styles/variables';

export const inputWrapperStyles = css`
  position: relative;
  border-radius: 8px;
  width: 100%;
`;

export const inputStyles = css`
  position: block;
  width: 100%;
  border: none;
  padding: ${space2} ${space3};
  border-radius: 8px;
  transition: outline-color 200ms ease-out;
  outline: 1px solid white;
  font-size: 1.6rem;

  :focus {
    outline-color: ${primary};
  }

  ${tabletSmall} {
    padding: ${space2};
  }
`;

export const inputCloseIconStyles = css`
  font-size: ${font_xl};
  position: absolute;
  display: flex;
  align-items: center;
  right: ${space3};
  top: 50%;
  transform: translateY(-50%);
  fill: ${primary};
  height: ${space4};
  width: auto;
  cursor: pointer;
`;
