import { css } from '@emotion/css';
import styled from '@emotion/styled';
import { blue, green40, yellow } from 'assets/styles/colors';

import { baseUnit } from 'assets/styles/utils';
import { font_sm, space2 } from 'assets/styles/variables';
import { ResponseProduct } from 'types/product';

export const wrapperStyles = css`
  display: flex;
  flex-direction: column;
  margin-right: ${space2};
`;

type StyledProps = {
  product: ResponseProduct;
  prefix?: string;
};

const getNutrientPercentage = (
  product: ResponseProduct,
  prefix: string,
  nutrient: 'carbs' | 'protein' | 'fats'
): number => {
  const key = `${prefix}_${nutrient}_percentage`;
  return Number(product[key as keyof ResponseProduct] ?? 0);
};

const getCarbsDegrees = (product: ResponseProduct, prefix: string) =>
  `${getNutrientPercentage(product, prefix, 'carbs') * 3.6}deg`;

const getProteinDegrees = (product: ResponseProduct, prefix: string) => {
  const carbsDegrees = getNutrientPercentage(product, prefix, 'carbs') * 3.6;
  const proteinDegrees =
    getNutrientPercentage(product, prefix, 'protein') * 3.6;
  return `${carbsDegrees}deg ${carbsDegrees + proteinDegrees}deg`;
};

const getFatsDegrees = (product: ResponseProduct, prefix: string) => {
  const carbsDegrees = getNutrientPercentage(product, prefix, 'carbs') * 3.6;
  const proteinDegrees =
    getNutrientPercentage(product, prefix, 'protein') * 3.6;
  return `${carbsDegrees + proteinDegrees}deg`;
};

const circleDimensions = 46;
const circleThickness = 3;

export const NutrientsCircleWrapper = styled.div`
  width: ${circleDimensions}px;
  height: ${circleDimensions}px;
  position: relative;
  background-image: conic-gradient(
    ${blue}
      ${({ product, prefix = 'base' }: StyledProps) =>
        getCarbsDegrees(product, prefix)},
    ${green40}
      ${({ product, prefix = 'base' }: StyledProps) =>
        getProteinDegrees(product, prefix)},
    ${yellow}
      ${({ product, prefix = 'base' }: StyledProps) =>
        getFatsDegrees(product, prefix)}
  );
  border-radius: 50%;
  transform: rotate(-90deg);
  margin-top: ${baseUnit * 0.8}rem;
`;

const circleContentDimensions = circleDimensions - circleThickness * 2;

export const nutrientsCircleContentStyles = css`
  position: absolute;
  display: flex;
  flex-direction: column;
  line-height: 12px;
  align-items: center;
  justify-content: center;
  font-size: ${font_sm};
  background-color: #fff;
  top: ${circleThickness}px;
  left: ${circleThickness}px;
  width: ${circleContentDimensions}px;
  height: ${circleContentDimensions}px;
  border-radius: 50%;
  transform: rotate(90deg);
  font-weight: 500;
`;
