import { useNavigate } from 'react-router';

import { PRODUCT_URL_SEGMENT } from 'constants/routes';
import { NutritionalFactDifferenceData, ResponseProduct } from 'types/product';

import {
  PRODUCT_CARD_NAME_TEST_ID,
  PRODUCT_CARD_TEST_ID
} from 'constants/tests';

import Price from './Price/Price';
import NutritionalFacts from 'components/NutritionalFacts/NutritionalFacts';
import ProductImage from 'components/ProductImage/ProductImage';

import {
  productCardContentWrapperStyles,
  productCardImageStyles,
  productCardImageWrapperStyles,
  productCardNameStyles,
  productCardNameAndPriceWrapperStyles,
  productCardWrapperStyles
} from './ProductCard.styles';
import { useProductDisplay } from 'contexts/ProductDisplayContext';

type Props = {
  product: ResponseProduct;
  differenceData?: NutritionalFactDifferenceData;
  highlight?: boolean;
};

const ProductCard = ({ product, differenceData, highlight = false }: Props) => {
  const navigate = useNavigate();
  const { activeUnit } = useProductDisplay();

  return (
    <div
      data-testid={PRODUCT_CARD_TEST_ID}
      onClick={() =>
        navigate(
          `/${PRODUCT_URL_SEGMENT}/${product.meta_ean}${window.location.search}`
        )
      }
      className={productCardWrapperStyles({ highlight })}
    >
      <ProductImage
        wrapperStyles={productCardImageWrapperStyles({ highlight })}
        imageStyles={productCardImageStyles}
        productId={product.meta_ean}
      />

      <div className={productCardContentWrapperStyles}>
        <div className={productCardNameAndPriceWrapperStyles}>
          <h2
            data-testid={PRODUCT_CARD_NAME_TEST_ID}
            className={productCardNameStyles}
          >
            {product.name}
          </h2>

          <Price number={product.price_current} />
        </div>

        <NutritionalFacts
          differenceData={differenceData}
          product={product}
          unit={activeUnit}
        />
      </div>
    </div>
  );
};

export default ProductCard;
