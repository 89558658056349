import { StyledButton } from './Button.styles';

export type Props = {
  text: string;
  onClick: () => void;
  filled?: boolean;
  disabled?: boolean;
  fullWidth?: boolean;
};

const Button = ({ text, onClick, filled, disabled, fullWidth }: Props) => {
  return (
    <StyledButton
      disabled={disabled}
      filled={filled}
      fullWidth={fullWidth}
      type="button"
      onClick={onClick}
    >
      {text}
    </StyledButton>
  );
};

export default Button;
