import { useEffect, useState } from 'react';

const useHasClickedOutside = (
  resultsWrapperRef: React.MutableRefObject<any>
) => {
  const [hasClickedOutside, setHasClickedOutside] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (resultsWrapperRef.current === undefined) {
        return;
      }

      const clickedOutside =
        !!resultsWrapperRef.current &&
        !resultsWrapperRef.current.contains(event.target);

      if (clickedOutside !== hasClickedOutside) {
        setHasClickedOutside(clickedOutside);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [resultsWrapperRef, hasClickedOutside]);

  return hasClickedOutside;
};

export default useHasClickedOutside;
