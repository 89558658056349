import { css } from '@emotion/css';
import { space3 } from 'assets/styles/variables';

export const wrapperStyles = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100vh;
  padding: ${space3};
  max-width: 1200px;
  margin: 0 auto;
`;
