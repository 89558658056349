import { css } from '@emotion/css';
import {
  tabletLarge,
  tabletMedium,
  tabletSmall
} from 'assets/styles/media-queries';
import { space3 } from 'assets/styles/variables';

export const ProductsListWrapperStyles = css`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(4, auto);
  grid-gap: ${space3};
  width: 100%;

  ${tabletLarge} {
    grid-template-columns: repeat(3, auto);
  }

  ${tabletMedium} {
    grid-template-columns: repeat(2, auto);
  }

  ${tabletSmall} {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0;
  }
`;
