export const BREAKPOINTS = {
  xs: '320px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1400px'
} as const;

export const QUERIES = {
  xs: `@media (max-width: ${BREAKPOINTS.xs})`,
  sm: `@media (max-width: ${BREAKPOINTS.sm})`,
  md: `@media (max-width: ${BREAKPOINTS.md})`,
  lg: `@media (max-width: ${BREAKPOINTS.lg})`,
  xl: `@media (max-width: ${BREAKPOINTS.xl})`,
  xxl: `@media (max-width: ${BREAKPOINTS.xxl})`
} as const;

export const COOKIE_SIZES = {
  xs: 100,
  sm: 150,
  md: 200,
  lg: 250,
  xl: 300,
  xxl: 350
} as const;
