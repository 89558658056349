import React, { useState, useMemo, useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import debounce from 'lodash/debounce';

import { ResponseProduct } from 'types/product';
import { Status } from 'types/api';
import { fetchFromApi } from 'api/fetch';

import ResultsPanel from './ResultsPanel/ResultsPanel';
import SearchInput from './SearchInput/SearchInput';

import { wrapperStyles } from './ProductSearch.styles';

const ProductSearch = () => {
  const [searchData, setSearchData] = useState<{
    searchValue: string;
    status: Status;
    products: ResponseProduct[];
  }>({ searchValue: '', status: Status.Idle, products: [] });
  const [resultsPanelIsVisible, setResultsPanelIsVisible] =
    useState<boolean>(false);

  const fetchProducts = async (search: string): Promise<ResponseProduct[]> => {
    try {
      if (!search) return [];

      const isEan = /^\d+$/.test(search);
      if (isEan && search.length < 8) {
        throw new Error('EAN must be at least 8 digits');
      }

      const baseParams = { limit: '12' };
      const searchParams = {
        ...baseParams,
        ...(isEan ? { ean: search } : { name: search })
      };

      try {
        return await fetchFromApi<ResponseProduct[]>(
          '/products/search',
          searchParams
        );
      } catch (apiError: any) {
        if (apiError.status === 404) {
          return [];
        }

        throw apiError;
      }
    } catch (error) {
      console.error('Error fetching products:', error);
      throw error;
    }
  };

  const debouncedSearch = useMemo(
    () =>
      debounce((term: string) => {
        setSearchData(prev => ({ ...prev, searchValue: term }));
      }, 300),
    []
  );

  const {
    data: products,
    isLoading,
    error
  } = useQuery({
    queryKey: ['products', searchData.searchValue],
    queryFn: () => fetchProducts(searchData.searchValue),
    enabled: searchData.searchValue.length >= 2
  });

  useEffect(() => {
    if (error) {
      setSearchData(prev => ({
        ...prev,
        status: Status.Rejected,
        products: []
      }));
    } else if (isLoading) {
      setSearchData(prev => ({ ...prev, status: Status.Pending }));
    } else if (products) {
      setSearchData(prev => ({
        ...prev,
        status: products.length === 0 ? Status.NotFound : Status.Resolved,
        products: products
      }));
    }
  }, [error, isLoading, products]);

  useEffect(() => {
    makeResultsPanelVisibleIfHasSearchValue();
  }, [searchData.searchValue]);

  const clearSearchValue = () =>
    setSearchData(prevSearchData => ({
      ...prevSearchData,
      searchValue: ''
    }));

  const saveSearchValueToState = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    event.preventDefault();
    debouncedSearch(event.target.value);
    setSearchData(prev => ({ ...prev, searchValue: event.target.value }));
  };

  const makeResultsPanelVisibleIfHasSearchValue = () => {
    const hasSearchValue = searchData.searchValue !== '';

    if (hasSearchValue) {
      setResultsPanelIsVisible(true);
    }
  };

  return (
    <div className={wrapperStyles}>
      <SearchInput
        onChange={event => saveSearchValueToState(event)}
        onFocus={makeResultsPanelVisibleIfHasSearchValue}
        searchValue={searchData.searchValue}
        onCloseIconClick={() => {
          clearSearchValue();
          setResultsPanelIsVisible(false);
        }}
      />

      <ResultsPanel
        isVisible={resultsPanelIsVisible}
        setIsVisible={setResultsPanelIsVisible}
        searchStatus={searchData.status}
        productResults={searchData.products}
      />
    </div>
  );
};

export default ProductSearch;
