import React, { createContext, useState, FC } from 'react';
import { OrderByDataItem } from 'types/sort';
import { sortItems, orderByData } from './data/sort';

export type SortContextState = {
  globalSelectedSortValue: string;
  setGlobalSelectedSortValue: React.Dispatch<React.SetStateAction<string>>;
  orderByData: OrderByDataItem | undefined;
  sortItems: { label: string; value: string }[];
};

export const initialState = {
  globalSelectedSortValue: '',
  setGlobalSelectedSortValue: () => {},
  orderByData: undefined,
  sortItems
};

export const SortContext = createContext<SortContextState>(initialState);

const SortProvider: FC = ({ children }) => {
  const [globalSelectedSortValue, setGlobalSelectedSortValue] = useState('');

  return (
    <SortContext.Provider
      value={{
        globalSelectedSortValue,
        setGlobalSelectedSortValue,
        sortItems,
        orderByData: orderByData[globalSelectedSortValue]
      }}
    >
      {children}
    </SortContext.Provider>
  );
};

export default SortProvider;
