import { css } from '@emotion/css';
import { tabletSmall } from 'assets/styles/media-queries';
import { font_sm, space4, space6 } from 'assets/styles/variables';

export const wrapperStyles = css`
  position: relative;
  width: 100%;
  margin: ${space6} 0;

  ${tabletSmall} {
    margin: ${space4} 0;
    font-size: ${font_sm};
  }
`;

export const skeletonWrapperStyles = css`
  padding: ${space4};
`;
