import { css } from '@emotion/css';
import { gray30 } from 'assets/styles/colors';
import { mobileLarge, tabletMedium } from 'assets/styles/media-queries';
import {
  font_xl,
  font_xxl,
  space3,
  space5,
  space8
} from 'assets/styles/variables';
import { space } from 'assets/styles/utils';

export const wrapperStyles = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const iconStyles = css`
  height: ${space(13)};
  margin: ${space5} 0;
  fill: ${gray30};

  ${tabletMedium} {
    height: ${space8};
    margin: ${space3} 0;
  }

  ${mobileLarge} {
    height: ${space8};
  }
`;

export const textStyles = css`
  font-size: ${font_xxl};
  color: ${gray30};
  margin-bottom: ${space5};

  ${tabletMedium} {
    font-size: ${font_xl};
    margin-bottom: ${space3};
  }
`;
