import {
  centStyles,
  centsWrapperStyles,
  eurosStyles,
  priceWrapperStyles,
  symbolStyles
} from './Price.styles';

type Props = { number: number };

const Price = ({ number }: Props) => {
  const totalPrice = number.toString().substring(0, 4);
  const euros = totalPrice.split('.')[0];
  const cents = totalPrice.split('.')[1];

  return (
    <div className={priceWrapperStyles}>
      <h2 className={eurosStyles}>{euros}</h2>

      <span className={centsWrapperStyles}>
        <p className={centStyles}>{cents}</p>
        <span className={symbolStyles}>€</span>
      </span>
    </div>
  );
};

export default Price;
