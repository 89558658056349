import { css } from '@emotion/react';
import { gray10, black } from './colors';

export default css`
  html {
    font-size: 62.5%; /* 1rem = 10px, 62.5% of 16px = 10px */
  }

  body {
    line-height: 1.6;
    min-height: 100%;
    min-width: 100%;
    background-color: ${gray10};
    color: ${black};
  }

  h1,
  h2,
  h3,
  h4,
  p {
    margin: 0;
    padding: 0;
  }

  p {
    font-weight: 300;
  }

  h1,
  h2,
  h3 {
    font-weight: 500;
  }
`;
