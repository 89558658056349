import { OrderByData, SortItems } from 'types/sort';

import { NutritionalFactUnit, CustomNutrientType } from 'types/product';

import i18n from 'i18next';
import 'utils/i18n';

export const sortItems: SortItems = [
  { label: i18n.t('sortItems.lowestCalories'), value: 'lowest_calories' },
  { label: i18n.t('sortItems.highestCalories'), value: 'highest_calories' },
  { label: i18n.t('sortItems.lowestCarbsGrams'), value: 'lowest_carbs_grams' },
  {
    label: i18n.t('sortItems.highestCarbsGrams'),
    value: 'highest_carbs_grams'
  },
  {
    label: i18n.t('sortItems.lowestCarbsPercentage'),
    value: 'lowest_carbs_percentage'
  },
  {
    label: i18n.t('sortItems.highestCarbsPercentage'),
    value: 'highest_carbs_percentage'
  },
  {
    label: i18n.t('sortItems.lowestProteinGrams'),
    value: 'lowest_protein_grams'
  },
  {
    label: i18n.t('sortItems.highestProteinGrams'),
    value: 'highest_protein_grams'
  },
  {
    label: i18n.t('sortItems.lowestProteinPercentage'),
    value: 'lowest_protein_percentage'
  },
  {
    label: i18n.t('sortItems.highestProteinPercentage'),
    value: 'highest_protein_percentage'
  },
  { label: i18n.t('sortItems.lowestFatGrams'), value: 'lowest_fat_grams' },
  {
    label: i18n.t('sortItems.highestFatGrams'),
    value: 'highest_fat_grams'
  },
  {
    label: i18n.t('sortItems.lowestFatPercentage'),
    value: 'lowest_fat_percentage'
  },
  {
    label: i18n.t('sortItems.highestFatPercentage'),
    value: 'highest_fat_percentage'
  }
];

export const orderByData: OrderByData = {
  lowest_calories: { key: 'totalEnergy', direction: 'asc' },
  highest_calories: { key: 'totalEnergy', direction: 'desc' },
  lowest_carbs_grams: { key: 'carbsInGrams', direction: 'asc' },
  highest_carbs_grams: { key: 'carbsInGrams', direction: 'desc' },
  lowest_carbs_percentage: { key: 'carbsPercentage', direction: 'asc' },
  highest_carbs_percentage: { key: 'carbsPercentage', direction: 'desc' },
  lowest_protein_grams: { key: 'proteinInGrams', direction: 'asc' },
  highest_protein_grams: { key: 'proteinInGrams', direction: 'desc' },
  lowest_protein_percentage: { key: 'proteinPercentage', direction: 'asc' },
  highest_protein_percentage: { key: 'proteinPercentage', direction: 'desc' },
  lowest_fat_grams: { key: 'fatsInGrams', direction: 'asc' },
  highest_fat_grams: { key: 'fatsInGrams', direction: 'desc' },
  lowest_fat_percentage: { key: 'fatsPercentage', direction: 'asc' },
  highest_fat_percentage: { key: 'fatsPercentage', direction: 'desc' }
};

export const nutritionalFactDifferenceData: {
  [key: string]: {
    valueKey: keyof CustomNutrientType;
    unit: NutritionalFactUnit;
    macro: string;
  };
} = {
  lowest_calories: {
    valueKey: 'totalEnergy',
    unit: 'cal',
    macro: 'Calories'
  },
  highest_calories: {
    valueKey: 'totalEnergy',
    unit: 'cal',
    macro: 'Calories'
  },
  lowest_carbs_grams: { valueKey: 'carbsInGrams', unit: 'g', macro: 'Carbs' },
  highest_carbs_grams: { valueKey: 'carbsInGrams', unit: 'g', macro: 'Carbs' },
  lowest_carbs_percentage: {
    valueKey: 'carbsPercentage',
    unit: '%',
    macro: 'Carbs'
  },
  highest_carbs_percentage: {
    valueKey: 'carbsPercentage',
    unit: '%',
    macro: 'Carbs'
  },
  lowest_protein_grams: {
    valueKey: 'proteinInGrams',
    unit: 'g',
    macro: 'Protein'
  },
  highest_protein_grams: {
    valueKey: 'proteinInGrams',
    unit: 'g',
    macro: 'Protein'
  },
  lowest_protein_percentage: {
    valueKey: 'proteinPercentage',
    unit: '%',
    macro: 'Protein'
  },
  highest_protein_percentage: {
    valueKey: 'proteinPercentage',
    unit: '%',
    macro: 'Protein'
  },
  lowest_fat_grams: { valueKey: 'fatsInGrams', unit: 'g', macro: 'Fats' },
  highest_fat_grams: { valueKey: 'fatsInGrams', unit: 'g', macro: 'Fats' },
  lowest_fat_percentage: {
    valueKey: 'fatsPercentage',
    unit: '%',
    macro: 'Fats'
  },
  highest_fat_percentage: {
    valueKey: 'fatsPercentage',
    unit: '%',
    macro: 'Fats'
  }
};
