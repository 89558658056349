import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import ProductsList from 'components/ProductsList/ProductsList';
import { ResponseProduct } from 'types/product';
import styled from '@emotion/styled';
import {
  boxShadow10,
  font_lg,
  font_md,
  font_xl,
  space2,
  space3,
  space4,
  space5,
  space6
} from 'assets/styles/variables';
import {
  mobileLarge,
  mobileSmall,
  tabletSmall
} from 'assets/styles/media-queries';
import { gray20 } from 'assets/styles/colors';
import { orange } from 'assets/styles/colors';
import { fetchFromApi } from 'api/fetch';
import { useSortContext, SortKeys } from 'contexts/SortContext';
import { useProductDisplay } from 'contexts/ProductDisplayContext';

type Props = {
  limit?: number;
  singleProduct?: ResponseProduct;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${space6};
`;

const SortingControls = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${space5};

  ${mobileSmall} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const SortByLabel = styled.label`
  font-size: ${font_xl};
  margin-right: ${space5};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  ${tabletSmall} {
    word-wrap: break-word;
    font-size: ${font_lg};

    display: inline;
    -webkit-line-clamp: inherit;
    -webkit-box-orient: inherit;

    width: 88%;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  ${mobileLarge} {
    font-size: ${font_md};
  }

  ${mobileSmall} {
    margin-bottom: ${space2};
  }
`;

const SortSelect = styled.select`
  padding: ${space2} ${space4} ${space2} ${space3};
  border: 1px solid ${gray20};
  border-radius: 4px;
  background-color: white;
  font-size: ${font_md};
  margin-right: ${space2};
  cursor: pointer;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right ${space3} center;
  background-size: 1em;
  min-width: 150px;
  transition: border-color 200ms ease-in-out;

  &:hover {
    border-color: ${orange};
  }

  &:focus {
    outline: none;
    border-color: ${orange};
    box-shadow: ${boxShadow10};
  }

  &::-ms-expand {
    display: none;
  }

  option {
    background-color: white;
    color: #333;
  }

  ${mobileSmall} {
    margin-bottom: ${space2};
  }
`;

const SortBySelect = ({
  options
}: {
  options: Partial<Record<SortKeys, { label: string }>>;
}) => {
  const { sortBy, handleSortByChange } = useSortContext();

  return (
    <SortSelect
      value={sortBy}
      onChange={e => handleSortByChange(e.target.value as SortKeys)}
    >
      {Object.entries(options).map(([value, { label }]) => (
        <option key={value} value={value}>
          {label}
        </option>
      ))}
    </SortSelect>
  );
};

const SortOrderSelect = () => {
  const { sortOrder, handleSortOrderChange } = useSortContext();

  return (
    <SortSelect
      value={sortOrder}
      onChange={e => handleSortOrderChange(e.target.value as 'asc' | 'desc')}
    >
      <option value="desc">High to low</option>
      <option value="asc">Low to high</option>
    </SortSelect>
  );
};

const SimilarProductsList = ({ limit = 12, singleProduct }: Props) => {
  const [products, setProducts] = useState<ResponseProduct[]>([]);
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const { sortBy, sortOrder } = useSortContext();
  const { activeUnit } = useProductDisplay();

  const SORT_OPTIONS: Partial<Record<SortKeys, { label: string }>> = {
    [activeUnit === 'package'
      ? SortKeys.PACKAGE_CALORIES
      : SortKeys.BASE_CALORIES]: { label: 'Calories' },
    [SortKeys.PROTEIN_PERCENT]: { label: 'Protein / Calorie' },
    [SortKeys.FAT_PERCENT]: { label: 'Fat / Calorie' },
    [SortKeys.CARBS_PERCENT]: { label: 'Carbs / Calorie' },
    [activeUnit === 'package'
      ? SortKeys.TOTAL_PACKAGE_PROTEIN
      : SortKeys.TOTAL_PROTEIN]: { label: 'Total protein' },
    [activeUnit === 'package'
      ? SortKeys.TOTAL_PACKAGE_FAT
      : SortKeys.TOTAL_FAT]: { label: 'Total fat' },
    [activeUnit === 'package'
      ? SortKeys.TOTAL_PACKAGE_CARBS
      : SortKeys.TOTAL_CARBS]: { label: 'Total carbs' },
    [SortKeys.PROTEIN_PER_EURO]: { label: 'Protein / Euro' },
    [SortKeys.FAT_PER_EURO]: { label: 'Fat / Euro' },
    [SortKeys.CARBS_PER_EURO]: { label: 'Carbs / Euro' }
  };

  useEffect(() => {
    const fetchSimilarProducts = async () => {
      try {
        const params = {
          sort_by: sortBy,
          sort_order: sortOrder,
          limit: String(limit)
        };

        const data = await fetchFromApi<ResponseProduct[]>(
          `/products/similar/${id}`,
          params
        );
        setProducts(data);
      } catch (error) {
        console.error('Error fetching similar products:', error);
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      fetchSimilarProducts();
    }
  }, [id, sortBy, sortOrder, limit]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Wrapper>
      <SortingControls>
        <SortByLabel>Sort by: </SortByLabel>
        <SortBySelect options={SORT_OPTIONS} />
        <SortOrderSelect />
      </SortingControls>

      <ProductsList products={products} singleProduct={singleProduct} />
    </Wrapper>
  );
};

export default SimilarProductsList;
