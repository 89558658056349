import { css } from '@emotion/css';

import { baseUnit } from 'assets/styles/utils';

import { font_sm } from 'assets/styles/variables';

export const wrapperStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${font_sm};
  font-weight: 500;
`;

export const separatorStyles = css`
  margin: 0 auto;
  width: 2px;
  height: 2px;
  border-radius: 50%;
  margin: ${baseUnit * 0.5}rem auto;
`;
