import { css } from '@emotion/css';

import { white } from 'assets/styles/colors';
import { boxShadow10, space2, space4 } from 'assets/styles/variables';

export const panelWrapperStyles = css`
  position: absolute;
  z-index: 999;
  box-shadow: ${boxShadow10};
  overflow-y: auto;
  top: 120%;
  width: 100%;
  background-color: ${white};
`;

export const resultsStyles = css`
  height: 400px;
`;

export const skeletonWrapperStyles = css`
  padding: ${space2} ${space4};
`;
