import { css } from '@emotion/css';
import { tabletSmall } from 'assets/styles/media-queries';

export const logoStyles = css`
  height: 50px;

  ${tabletSmall} {
    height: 35px;
  }
`;
