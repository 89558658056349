import { getProductImage } from 'utils/products';

import { PRODUCT_IMAGE_PLACEHOLDER_SRC } from 'constants/products';
import { PRODUCT_IMAGE_WRAPPER_TEST_ID } from 'constants/tests';

type Props = {
  wrapperStyles: string;
  imageStyles: string;
  productId: string;
  alt?: string;
};

const ProductImage = ({
  wrapperStyles,
  imageStyles,
  productId,
  alt = 'Product image'
}: Props) => {
  const setImageSrcToPlaceholder = ({
    currentTarget
  }: React.SyntheticEvent<HTMLImageElement, Event>) => {
    currentTarget.onerror = null;
    currentTarget.src = PRODUCT_IMAGE_PLACEHOLDER_SRC;
  };

  return (
    <div data-testid={PRODUCT_IMAGE_WRAPPER_TEST_ID} className={wrapperStyles}>
      <img
        className={imageStyles}
        onError={setImageSrcToPlaceholder}
        src={getProductImage(productId)}
        alt={alt}
      />
    </div>
  );
};

export default ProductImage;
