import { createContext, useContext, ReactNode, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export enum SortKeys {
  BASE_CALORIES = 'base_nutrient_energy_kcal',
  PACKAGE_CALORIES = 'package_nutrient_energy_kcal',
  PROTEIN_PERCENT = 'base_protein_percentage',
  FAT_PERCENT = 'base_fat_percentage',
  CARBS_PERCENT = 'base_carbs_percentage',
  TOTAL_PROTEIN = 'base_nutrient_protein',
  TOTAL_PACKAGE_PROTEIN = 'package_nutrient_protein',
  TOTAL_FAT = 'base_nutrient_fat',
  TOTAL_PACKAGE_FAT = 'package_nutrient_fat',
  TOTAL_CARBS = 'base_nutrient_carbs',
  TOTAL_PACKAGE_CARBS = 'package_nutrient_carbs',
  PROTEIN_PER_EURO = 'grams_protein_per_euro',
  FAT_PER_EURO = 'grams_fat_per_euro',
  CARBS_PER_EURO = 'grams_carbs_per_euro'
}

type SortContextType = {
  sortBy: SortKeys;
  sortOrder: 'asc' | 'desc';
  handleSortByChange: (value: SortKeys) => void;
  handleSortOrderChange: (value: 'asc' | 'desc') => void;
};

const SortContext = createContext<SortContextType | undefined>(undefined);

export const SortProvider = ({ children }: { children: ReactNode }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const defaultSortBy = SortKeys.PROTEIN_PERCENT;
  const defaultSortOrder = 'desc' as const;

  useEffect(() => {
    if (!searchParams.has('sort_by')) {
      const newParams = new URLSearchParams(searchParams);
      newParams.set('sort_by', defaultSortBy);
      newParams.set('sort_order', defaultSortOrder);
      setSearchParams(newParams);
    }
  }, []);

  const handleSortByChange = (value: SortKeys) => {
    const newParams = new URLSearchParams(searchParams);
    newParams.set('sort_by', value);
    setSearchParams(newParams);
  };

  const handleSortOrderChange = (value: 'asc' | 'desc') => {
    const newParams = new URLSearchParams(searchParams);
    newParams.set('sort_order', value);
    setSearchParams(newParams);
  };

  const value = {
    sortBy: (searchParams.get('sort_by') as SortKeys) || defaultSortBy,
    sortOrder:
      (searchParams.get('sort_order') as 'asc' | 'desc') || defaultSortOrder,
    handleSortByChange,
    handleSortOrderChange
  };

  return <SortContext.Provider value={value}>{children}</SortContext.Provider>;
};

export const useSortContext = () => {
  const context = useContext(SortContext);
  if (context === undefined) {
    throw new Error('useSortContext must be used within a SortProvider');
  }
  return context;
};
