import ProductSearch from 'components/ProductSearch/ProductSearch';
import styled from '@emotion/styled';
import { space5, space8 } from 'assets/styles/variables';
import AnimatedCookie from 'components/AnimatedCookie/AnimatedCookie';

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${space8} ${space5};
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 700px;
  width: 100%;
`;

const Search = () => {
  return (
    <PageWrapper>
      <ContentWrapper>
        <AnimatedCookie />
        <ProductSearch />
      </ContentWrapper>
    </PageWrapper>
  );
};

export default Search;
