import {
  createContext,
  useContext,
  ReactNode,
  useState,
  useEffect
} from 'react';

export type UnitType = '100gml' | 'package';

export const setStoredProductUnit = (unit: UnitType) => {
  localStorage.setItem('productUnit', unit);
};

type ProductDisplayContextType = {
  activeUnit: UnitType;
  setActiveUnit: (unit: UnitType) => void;
};

const ProductDisplayContext = createContext<
  ProductDisplayContextType | undefined
>(undefined);

export const ProductDisplayProvider = ({
  children
}: {
  children: ReactNode;
}) => {
  const [activeUnit, setActiveUnit] = useState<UnitType>(() => {
    const storedUnit = localStorage.getItem('productUnit') as UnitType;
    if (storedUnit) return storedUnit;

    const params = new URLSearchParams(window.location.search);
    const urlUnit = params.get('unit') as UnitType;
    return urlUnit || '100gml';
  });

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    params.set('unit', activeUnit);
    window.history.replaceState(
      {},
      '',
      `${window.location.pathname}?${params.toString()}`
    );

    setStoredProductUnit(activeUnit);
  }, [activeUnit]);

  const value = {
    activeUnit,
    setActiveUnit
  };

  return (
    <ProductDisplayContext.Provider value={value}>
      {children}
    </ProductDisplayContext.Provider>
  );
};

export const useProductDisplay = () => {
  const context = useContext(ProductDisplayContext);
  if (context === undefined) {
    throw new Error(
      'useProductDisplay must be used within a ProductDisplayProvider'
    );
  }
  return context;
};
