import { API_BASE_URL } from 'config';

export class ApiError extends Error {
  constructor(
    message: string,
    public status?: number,
    public statusText?: string
  ) {
    super(message);
    this.name = 'ApiError';
  }
}

export async function fetchFromApi<T>(
  endpoint: string,
  params: Record<string, string | number | boolean> = {}
): Promise<T> {
  const queryString = new URLSearchParams(
    Object.entries(params).map(([key, value]) => [key, String(value)])
  ).toString();
  const url = `${API_BASE_URL}${endpoint}?${queryString}`;

  const response = await fetch(url);
  if (!response.ok) {
    throw new ApiError(
      'Failed to fetch data',
      response.status,
      response.statusText
    );
  }
  return response.json();
}
