import { useRef } from 'react';
import { Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';

import useHasClickedOutside from 'hooks/useHasClickedOutside';

import { ResponseProduct } from 'types/product';
import {
  RESULTS_PANEL_SKELETON_WRAPPER_TEST_ID,
  RESULTS_PANEL_WRAPPER_TEST_ID
} from 'constants/tests';

import { Status } from 'types/api';

import ProductsNotFound from './ProductsNotFound/ProductsNotFound';
import ProductSearchItem from './ProductSearchItem/ProductSearchItem';

import {
  resultsStyles,
  panelWrapperStyles,
  skeletonWrapperStyles
} from './ResultsPanel.styles';

type Props = {
  searchStatus: Status;
  productResults: ResponseProduct[];
  isVisible: boolean;
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

const ResultsPanel = ({
  searchStatus,
  productResults,
  isVisible,
  setIsVisible
}: Props) => {
  const { t } = useTranslation();
  const resultsWrapperRef: React.MutableRefObject<any> = useRef();
  const hasClickedOutside = useHasClickedOutside(resultsWrapperRef);

  const isSearching = searchStatus === Status.Pending;
  const noProductsFound =
    searchStatus === Status.NotFound && productResults.length === 0;
  const productsAreFound =
    searchStatus === Status.Resolved && productResults.length > 0;
  const hasError = searchStatus === Status.Rejected;

  const PanelWrapper = ({ children }: { children: JSX.Element }) => (
    <div
      data-testid={RESULTS_PANEL_WRAPPER_TEST_ID}
      ref={resultsWrapperRef}
      className={panelWrapperStyles}
    >
      {children}
    </div>
  );

  if (!isVisible || hasClickedOutside) {
    return null;
  }

  if (isSearching) {
    return (
      <PanelWrapper>
        <div
          data-testid={RESULTS_PANEL_SKELETON_WRAPPER_TEST_ID}
          className={skeletonWrapperStyles}
        >
          <Skeleton active paragraph={{ rows: 4 }} />
        </div>
      </PanelWrapper>
    );
  }

  if (noProductsFound) {
    return (
      <PanelWrapper>
        <ProductsNotFound />
      </PanelWrapper>
    );
  }

  if (productsAreFound) {
    return (
      <PanelWrapper>
        <div className={resultsStyles}>
          {productResults.map((product, index) => (
            <ProductSearchItem
              onClick={() => setIsVisible(false)}
              id={product.meta_ean}
              name={product.name}
              key={product.meta_ean + index}
            />
          ))}
        </div>
      </PanelWrapper>
    );
  }

  if (hasError) {
    return (
      <PanelWrapper>
        <p>{t('anUnexpectedErrorHasOccurred')}</p>
      </PanelWrapper>
    );
  }

  return null;
};

export default ResultsPanel;
