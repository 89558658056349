import { font, space } from './utils';

export const font_xs = font(0.8); // 0.8rem
export const font_sm = font(1); // 1rem
export const font_md = font(1.2); // 1.2rem
export const font_lg = font(1.4); // 1.4rem
export const font_xl = font(1.6); // 1.6rem
export const font_xxl = font(1.8); // 1.8rem

export const space1 = space(1); // 0.5rem
export const space2 = space(2); // 1rem
export const space3 = space(3); // 1.5rem
export const space4 = space(4); // 2rem
export const space5 = space(5); // 2.5rem
export const space6 = space(6); // 3rem
export const space7 = space(8); // 4rem
export const space8 = space(10); // 5rem

export const boxShadow10 = '0px 1px 20px -9px rgba(145, 145, 145, 0.45)';
export const boxShadow20 = '0px 1px 20px -9px rgba(145, 145, 145, 0.60)';
export const boxShadow30 = '0px 1px 20px -9px rgba(145, 145, 145, 0.75)';
export const boxShadow40 = '0px 1px 20px -9px rgba(145, 145, 145, 0.90)';
export const borderRadius = '8px';
