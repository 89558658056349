export const primary = '#d58a32';
export const white = '#fff';
export const black = '#000';
export const text = '#434449';
export const gray10 = '#F7F7F7';
export const gray20 = '#DDDDDD';
export const gray30 = '#c0c0c0';
export const blue = 'blue';
export const yellow = '#ffc107';
export const yellow60 = 'rgb(231 177 15)';
export const green = '#4caf50';
export const green40 = 'rgb(80 205 57)';
export const danger = '#ef5350';
export const orange = 'orange';
