import { PAGE_WRAPPER_TEST_ID } from 'constants/tests';

import Logo from 'components/Logo/Logo';
import ProductSearch from 'components/ProductSearch/ProductSearch';

import { wrapperStyles } from './PageWrapper.styles';

type Props = { children: JSX.Element | JSX.Element[] };

const PageWrapper = ({ children }: Props) => {
  return (
    <div data-testid={PAGE_WRAPPER_TEST_ID} className={wrapperStyles}>
      <Logo />
      <ProductSearch />

      {children}
    </div>
  );
};

export default PageWrapper;
