import DifferenceBadge from '../DifferenceBadge/DifferenceBadge';

import { black } from 'assets/styles/colors';
import {
  CALORIE_CIRCLE_WRAPPER_TEST_ID,
  CALORIE_COUNT_TEST_ID,
  NUTRIENTS_CIRCLE_WRAPPER_TEST_ID
} from 'constants/tests';
import { NutritionalFactDifferenceData, ResponseProduct } from 'types/product';

import {
  nutrientsCircleContentStyles,
  NutrientsCircleWrapper,
  wrapperStyles
} from './CalorieCircle.styles';

export type Props = {
  product: ResponseProduct;
  differenceData?: NutritionalFactDifferenceData;
  prefix?: string;
};

const CalorieCircle = ({ product, differenceData, prefix = 'base' }: Props) => {
  const isCaloriesDifferenceData = differenceData?.macro === 'Calories';

  return (
    <div data-testid={CALORIE_CIRCLE_WRAPPER_TEST_ID} className={wrapperStyles}>
      {isCaloriesDifferenceData && (
        <DifferenceBadge
          value={differenceData.value}
          unit={differenceData.unit}
          color={black}
        />
      )}

      <NutrientsCircleWrapper
        data-testid={NUTRIENTS_CIRCLE_WRAPPER_TEST_ID}
        product={product}
        prefix={prefix}
      >
        <div className={nutrientsCircleContentStyles}>
          <span data-testid={CALORIE_COUNT_TEST_ID}>
            {Math.round(
              Number(
                product[
                  `${prefix}_nutrient_energy_kcal` as keyof ResponseProduct
                ]
              )
            )}
          </span>

          <span>Cal</span>
        </div>
      </NutrientsCircleWrapper>
    </div>
  );
};

export default CalorieCircle;
