import { useTranslation } from 'react-i18next';

import { ReactComponent as BrokenCookieIcon } from 'assets/img/broken-cookie.svg';
import { iconStyles, wrapperStyles, textStyles } from './ErrorPanel.styles';

type Props = {
  error?: string;
  action?: JSX.Element;
};

const ErrorPanel = ({ error, action }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={wrapperStyles}>
      <BrokenCookieIcon className={iconStyles} />

      <p className={textStyles}>{error || t('anUnexpectedErrorHasOccurred')}</p>

      {action}
    </div>
  );
};

export default ErrorPanel;
