import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Global } from '@emotion/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { PRODUCT_ID_PARAM, PRODUCT_URL_SEGMENT } from 'constants/routes';
import SingleProduct from 'pages/SingleProduct/SingleProduct';

import globalStyles from 'assets/styles/global';
import 'antd/dist/antd.min.css';
import Search from 'pages/ProductSearch/Search';
import { SortProvider } from 'contexts/SortContext';
import { ProductDisplayProvider } from 'contexts/ProductDisplayContext';

const queryClient = new QueryClient();

function App() {
  return (
    <ProductDisplayProvider>
      <SortProvider>
        <QueryClientProvider client={queryClient}>
          <React.Fragment>
            <Global styles={globalStyles} />
            <Routes>
              <Route
                path={`/${PRODUCT_URL_SEGMENT}/${PRODUCT_ID_PARAM}`}
                element={<SingleProduct />}
              />
              <Route path="/" element={<Search />} />
            </Routes>
          </React.Fragment>
        </QueryClientProvider>
      </SortProvider>
    </ProductDisplayProvider>
  );
}

export default App;
