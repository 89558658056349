import { css } from '@emotion/css';

import { mobileLarge, tabletSmall } from 'assets/styles/media-queries';
import {
  font_md,
  font_sm,
  font_xs,
  space1,
  space2
} from 'assets/styles/variables';

export const macroWrapperStyles = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: ${font_xs};
  margin: 0 ${space2};

  ${mobileLarge} {
    margin: 0 ${space1};
  }
`;

export const macroPercentageStyles = css`
  font-size: ${font_md};
  font-weight: 400;
`;

export const macroGramsStyles = css`
  font-size: ${font_md};
  font-weight: 500;

  ${tabletSmall} {
    font-size: ${font_sm};
  }
`;

export const macroNameStyles = css`
  font-size: ${font_md};
  font-weight: 400;
`;
