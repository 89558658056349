import { NavigationItem } from 'types/product';

import i18n from 'i18next';
import 'utils/i18n';

export const getProductImage = (id: string) =>
  `https://cdn.s-cloud.fi/v1/w828_q75/product/ean/${id}_kuva1.jpg`;

const trimSlashesFromSlug = (slug: string) => {
  let trimmedSlug = slug;
  if (slug[0] === '/') {
    trimmedSlug = trimmedSlug.substring(1);
  }

  if (slug.slice(-1) === '/') {
    trimmedSlug = trimmedSlug.slice(0, -1);
  }

  return trimmedSlug;
};

export const findCategoryDataFromSlug = (
  navigationData: any[],
  slug: string
): NavigationItem => {
  if (slug === '/') {
    return {
      id: '',
      name: i18n.t('allProducts'),
      slug: '/',
      children: navigationData,
      __typename: 'typename'
    };
  }

  const trimmedSlug = trimSlashesFromSlug(slug);

  const slugLevel = trimmedSlug.split('/').filter(i => i !== '').length;
  let categoryData;

  switch (slugLevel) {
    case 1:
      categoryData = navigationData.find(item => item.slug === trimmedSlug);
      break;
    case 2:
      navigationData.forEach(item => {
        const foundCategoryData = item.children.find(
          (childItem: any) => childItem.slug === trimmedSlug
        );

        if (foundCategoryData) {
          categoryData = foundCategoryData;
        }
      });
      break;
    case 3:
      navigationData.forEach(item => {
        item.children.forEach((childItem: any) => {
          const foundCategoryData = childItem.children.find(
            (ndChildItem: any) => ndChildItem.slug === trimmedSlug
          );

          if (foundCategoryData) {
            categoryData = foundCategoryData;
          }
        });
      });
      break;
    case 4:
      navigationData.forEach(item => {
        item.children.forEach((childItem: any) => {
          childItem.children.forEach((ndChildItem: any) => {
            const foundCategoryData = ndChildItem.children.find(
              (rdChildItem: any) => rdChildItem.slug === trimmedSlug
            );

            if (foundCategoryData) {
              categoryData = foundCategoryData;
            }
          });
        });
      });
      break;
  }

  return categoryData;
};
