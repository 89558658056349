import { css } from '@emotion/css';
import { gray20 } from 'assets/styles/colors';
import {
  mobileLarge,
  mobileSmall,
  tabletSmall
} from 'assets/styles/media-queries';
import {
  boxShadow10,
  font_sm,
  space2,
  space3,
  space4
} from 'assets/styles/variables';

export const wrapperStyles = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: ${space4} 0;
  cursor: pointer;
  border-bottom: 1px solid ${gray20};
  transition: box-shadow 250ms ease-out;

  :hover {
    box-shadow: ${boxShadow10};
  }

  ${tabletSmall} {
    padding: ${space3};
  }

  ${mobileSmall} {
    padding: ${space2};
  }
`;

export const imageWrapperStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 50px;
  width: 12%;
  margin-right: ${space3};

  ${mobileSmall} {
    margin-right: ${space2};
  }
`;

export const imageStyles = css`
  max-height: 40px;
  max-width: 55px;

  ${mobileLarge} {
    max-height: 30px;
    max-width: 40px;
  }
`;

export const nameStyles = css`
  margin: 0;

  ${mobileSmall} {
    font-size: ${font_sm};
  }
`;
