import { css } from '@emotion/css';
import { tabletSmall } from 'assets/styles/media-queries';
import { font } from 'assets/styles/utils';
import { font_xxl, space2, space4 } from 'assets/styles/variables';

export const wrapperStyles = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${space2};
  margin: 0 auto;
`;

export const iconStyles = css`
  height: 120px;
  width: auto;

  ${tabletSmall} {
    height: auto;
    width: 40%;
  }
`;

export const textStyles = css`
  font-size: ${font(2.2)};
  margin: ${space4} 0;

  ${tabletSmall} {
    font-size: ${font_xxl};
  }
`;
