import {
  DIFFERENCE_BADGE_SEPARATOR_TEST_ID,
  DIFFERENCE_BADGE_TEST_ID
} from 'constants/tests';
import { NutritionalFactUnit } from 'types/product';

import { wrapperStyles, separatorStyles } from './DifferenceBadge.styles';

export type Props = {
  value: number;
  unit: NutritionalFactUnit;
  color: string;
};

function DifferenceBadge({ value, unit, color }: Props) {
  const isPositiveValue = value >= 0;

  const finalValue = `${isPositiveValue ? '+' : ''}${value}${unit}`;

  return (
    <div data-testid={DIFFERENCE_BADGE_TEST_ID}>
      <span style={{ color }} className={wrapperStyles}>
        {finalValue}
      </span>

      <div
        data-testid={DIFFERENCE_BADGE_SEPARATOR_TEST_ID}
        style={{ backgroundColor: color }}
        className={separatorStyles}
      />
    </div>
  );
}

export default DifferenceBadge;
