import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  // https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'fi',
    resources: {
      fi: {
        translation: {
          searchProduct: 'Hae tuote',
          productsNotFound: 'Tuotteita ei löytynyt.',
          allProducts: 'Kaikki tuotteet',
          products: 'tuotteet',
          sort: 'Järjestää',
          sortProducts: 'Järjestä tuotteita',
          apply: 'Käytä',
          similarProducts: 'Vastaavia tuotteita',
          anUnexpectedErrorHasOccurred: 'Odottamaton virhe on tapahtunut.',
          seeAllProducts: 'Katso kaikki tuotteet.',
          back: 'Takaisin',
          sortItems: {
            lowestCalories: 'Vähiten kaloreita',
            highestCalories: 'Korkeimmat kalorit',
            lowestCarbsGrams: 'Vähiten hiilihydraatteja - grammaa',
            highestCarbsGrams: 'Korkeimmat hiilihydraatit - grammaa',
            lowestCarbsPercentage: 'Pienin hiilihydraatti - prosentteina',
            highestCarbsPercentage: 'Korkein hiilihydraatti - prosentteina',
            lowestProteinGrams: 'Alhaisin proteiini - grammaa',
            highestProteinGrams: 'Korkein proteiini - grammaa',
            lowestProteinPercentage: 'Alhaisin proteiini - prosentteina',
            highestProteinPercentage: 'Korkein proteiini - prosentteina',
            lowestFatGrams: 'Alhaisimmat rasvat - grammaa',
            highestFatGrams: 'Korkein rasva - grammaa',
            lowestFatPercentage: 'Alhaisimmat rasvat - prosentteina',
            highestFatPercentage: 'Korkein rasva - prosentteina'
          }
        }
      }
    }
  });

export default i18n;
