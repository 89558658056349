import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';

import { logCurrentPage } from 'utils/ga';
import { fetchFromApi } from 'api/fetch';
import { ResponseProduct } from 'types/product';
import { Skeleton } from 'antd';

import PageWrapper from 'components/PageWrapper/PageWrapper';
import SingleProductCard from './sections/SingleProductCard/SingleProductCard';
import ErrorPanel from 'components/ErrorPanel/ErrorPanel';
import Button from 'components/Button/Button';
import SimilarProductsList from 'components/SimilarProductsList/SimilarProductsList';

const fetchProduct = async (
  ean: string | undefined
): Promise<ResponseProduct | null> => {
  try {
    if (!ean) return null;

    const isEan = /^\d+$/.test(ean);
    if (isEan && ean.length < 8) {
      throw new Error('EAN must be at least 8 digits');
    }

    try {
      const products = await fetchFromApi<ResponseProduct[]>(
        '/products/search',
        {
          limit: '1',
          ean
        }
      );
      return products[0] || null;
    } catch (apiError: any) {
      if (apiError.status === 404) {
        return null;
      }
      throw apiError;
    }
  } catch (error) {
    console.error('Error fetching product:', error);
    throw error;
  }
};

const SingleProduct = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const navigate = useNavigate();

  const {
    data: product,
    isLoading,
    error
  } = useQuery({
    queryKey: ['product', id],
    queryFn: () => fetchProduct(id),
    enabled: !!id
  });

  useEffect(() => {
    logCurrentPage();
  }, []);

  const isLoadingPageData = isLoading;
  const pageHasError = !!error;
  const pageDataIsFetched = !!product;

  if (isLoadingPageData) {
    return (
      <PageWrapper>
        <Skeleton active paragraph={{ rows: 4, width: '100%' }} />
      </PageWrapper>
    );
  }

  if (pageHasError) {
    return (
      <PageWrapper>
        <ErrorPanel
          action={
            <Button text={t('seeAllProducts')} onClick={() => navigate('/')} />
          }
        />
      </PageWrapper>
    );
  }

  if (pageDataIsFetched) {
    return (
      <PageWrapper>
        <SingleProductCard product={product} />
        <SimilarProductsList singleProduct={product} />
      </PageWrapper>
    );
  }

  return null;
};

export default SingleProduct;
