import { css } from '@emotion/css';
import { gray20, orange, white } from 'assets/styles/colors';
import { mobileLarge, tabletSmall } from 'assets/styles/media-queries';
import {
  boxShadow10,
  boxShadow20,
  font_lg,
  font_md,
  font_xl,
  space1,
  space2,
  space3,
  space4,
  space6
} from 'assets/styles/variables';

export const productCardWrapperStyles = ({
  highlight
}: {
  highlight?: boolean;
}) => css`
  display: flex;
  flex-direction: column;
  background-color: ${white};
  border-radius: 2px;
  transition: box-shadow 200ms ease-out, border 200ms ease-in-out;
  cursor: pointer;
  min-width: 0;
  box-shadow: ${highlight ? boxShadow10 : 'none'};
  box-sizing: border-box;
  border: 1px solid transparent;

  :hover {
    box-shadow: ${boxShadow20};
    border: 1px solid ${orange};
  }

  ${tabletSmall} {
    flex-direction: row;
    align-items: center;
    margin: ${space1};
    padding: ${space3};

    :hover {
      box-shadow: ${boxShadow10};
      border: 1px solid transparent;
    }
  }
`;

export const productCardImageWrapperStyles = ({
  highlight
}: {
  highlight?: boolean;
}) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 170px;
  border-bottom: 1px solid ${highlight ? orange : gray20};

  ${tabletSmall} {
    height: 100%;
    width: 20%;
    border-bottom: none;
    margin-right: ${space6};
  }
`;

export const productCardImageStyles = css`
  max-width: 125px;
  max-height: 125px;

  ${tabletSmall} {
    max-width: 100%;
    max-height: 50px;
  }

  ${mobileLarge} {
    max-height: 40px;
  }
`;

export const productCardContentWrapperStyles = css`
  display: flex;
  flex-direction: column;
  min-height: 58px;
  justify-content: space-between;
  align-items: center;
  padding: ${space6} ${space4};

  ${tabletSmall} {
    align-items: flex-start;
    width: 100%;
    padding: 0;
    overflow: hidden;
  }
`;

export const productCardNameAndPriceWrapperStyles = css`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: ${space6};

  ${tabletSmall} {
    margin-bottom: ${space2};
    align-items: center;
  }
`;

export const productCardNameStyles = css`
  font-size: ${font_xl};
  margin-right: ${space1};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  ${tabletSmall} {
    word-wrap: break-word;
    font-size: ${font_lg};

    display: inline;
    -webkit-line-clamp: inherit;
    -webkit-box-orient: inherit;

    width: 80%;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  ${mobileLarge} {
    font-size: ${font_md};
  }
`;
