import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import './index.css';
import 'utils/i18n';

import App from './App';

import SortProvider from 'context/SortProvider';

ReactDOM.render(
  <BrowserRouter>
    <SortProvider>
      <App />
    </SortProvider>
  </BrowserRouter>,
  document.getElementById('root')
);
