import { MACRO_GRAMS_TEST_ID, MACRO_PERCENTAGE_TEST_ID } from 'constants/tests';
import { NutritionalFactDifferenceData } from 'types/product';
import { getMacroWrapperDataCyValue } from 'utils/tests';

import DifferenceBadge from '../DifferenceBadge/DifferenceBadge';

import {
  macroGramsStyles,
  macroNameStyles,
  macroPercentageStyles,
  macroWrapperStyles
} from './Macro.styles';

export type Props = {
  percentage: number;
  grams: number;
  macroName: string;
  color: string;
  differenceData?: NutritionalFactDifferenceData;
};

const Macro = ({
  percentage,
  grams,
  macroName,
  color,
  differenceData
}: Props) => {
  const wrapperDataCyValue = getMacroWrapperDataCyValue(macroName);

  const differenceDataBelongsToMacro = differenceData?.macro === macroName;

  return (
    <div data-cy={wrapperDataCyValue} className={macroWrapperStyles}>
      {differenceDataBelongsToMacro && (
        <DifferenceBadge
          value={differenceData.value}
          unit={differenceData.unit}
          color={color}
        />
      )}

      <p
        data-testid={MACRO_PERCENTAGE_TEST_ID}
        className={macroPercentageStyles}
        style={{ color }}
      >
        {percentage}%
      </p>

      <p data-testid={MACRO_GRAMS_TEST_ID} className={macroGramsStyles}>
        {grams}g
      </p>

      <p className={macroNameStyles}>{macroName}</p>
    </div>
  );
};

export default Macro;
