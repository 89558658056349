export const getDataTestIdSelector = (testId: string) =>
  `[data-testid="${testId}"]`;

export const getDataCySelector = (value: string) => `[data-cy="${value}"]`;

export const removePercentSignFromString = (string: string) =>
  string.replace('%', '');

export const getMacroWrapperDataCyValue = (macroName: string) =>
  `${macroName}_Macro`;
