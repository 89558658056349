import { ResponseProduct } from 'types/product';
import {
  SINGLE_PRODUCT_CARD_NAME_TEST_ID,
  SINGLE_PRODUCT_CARD_TEST_ID
} from 'constants/tests';

import Price from 'components/ProductsList/ProductCard/Price/Price';
import NutritionalFacts from 'components/NutritionalFacts/NutritionalFacts';
import ProductImage from 'components/ProductImage/ProductImage';
import SegmentedControl from 'components/SegmentedControl/SegmentedControl';

import {
  singleProductDescriptionStyles,
  singleProductImageStyles,
  singleProductImageWrapperStyles,
  singleProductInfoWrapperStyles,
  singleProductNameStyles,
  singleProductWrapperStyles
} from './SingleProductCard.styles';
import styled from '@emotion/styled';
import { space4, space8 } from 'assets/styles/variables';
import { QUERIES } from 'assets/styles/breakpoints';
import { UnitType, useProductDisplay } from 'contexts/ProductDisplayContext';
import { SortKeys, useSortContext } from 'contexts/SortContext';
import { useEffect } from 'react';

type Props = {
  product: ResponseProduct;
};

const Wrapper = styled.div`
  border-radius: 8px;
`;

const FactsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${space8};

  ${QUERIES.md} {
    margin-top: ${space4};
    flex-direction: column;
    gap: ${space4};
  }
`;

const SingleProductCard = ({ product }: Props) => {
  const { activeUnit, setActiveUnit } = useProductDisplay();
  const { meta_id, name, price_current, description_en, package_size } =
    product;
  const { handleSortByChange, sortBy } = useSortContext();

  const hasPackageSize = Boolean(package_size[0]);

  useEffect(() => {
    if (!hasPackageSize) {
      setActiveUnit('100gml');
    }
  }, [hasPackageSize, setActiveUnit]);

  const handleOnUnitChange = (unit: UnitType) => {
    const isPackageUnit = unit === 'package';

    const updatedSortBy = isPackageUnit
      ? sortBy.replace('base', 'package')
      : sortBy.replace('package', 'base');

    if (Object.values(SortKeys).includes(updatedSortBy as SortKeys)) {
      handleSortByChange(updatedSortBy as SortKeys);
    }

    setActiveUnit(unit);
  };

  const unitOptions = [
    ...(hasPackageSize ? [{ value: 'package', label: 'Package' }] : []),
    { value: '100gml', label: '100g/ml' }
  ] as Array<{ value: UnitType; label: string }>;

  return (
    <Wrapper
      data-testid={SINGLE_PRODUCT_CARD_TEST_ID}
      className={singleProductWrapperStyles}
    >
      <ProductImage
        wrapperStyles={singleProductImageWrapperStyles}
        imageStyles={singleProductImageStyles}
        productId={meta_id}
      />

      <div className={singleProductInfoWrapperStyles}>
        <p
          data-testid={SINGLE_PRODUCT_CARD_NAME_TEST_ID}
          className={singleProductNameStyles}
        >
          {name}
        </p>

        <Price number={price_current} />

        <p className={singleProductDescriptionStyles}>{description_en}</p>

        <FactsWrapper>
          <SegmentedControl
            options={unitOptions}
            activeOption={activeUnit}
            onChange={handleOnUnitChange}
          />
          <NutritionalFacts product={product} unit={activeUnit} />
        </FactsWrapper>
      </div>
    </Wrapper>
  );
};

export default SingleProductCard;
